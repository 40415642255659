jQuery.fn.cssInt = function (prop) {
    return parseInt(this.css(prop), 10) || 0;
};

jQuery.fn.cssFloat = function (prop) {
    return parseFloat(this.css(prop)) || 0;
};


class MenuEntriesAdmin {
    constructor(container, menu, options = null) {
        this.container = $(container);
        this.toDelete = new Array();
        this.menuId = menu;
        this.options = {
            maxLevels: 3
        };
        if (options !== null) {
            this.options = Object.assign(this.options, options);
        }
        this.maxLevels = this.options.maxLevels;

        if (this.container.length > 0) {
            this.init();
        }
    }

    async init() {
        this.container.html(`
            <div class="center-align">
                <i>Einträge werden geladen</i>
            </div>
        `);

        let entries = await this.getEntries();

        if (!entries) {
            this.container.html(`
                <div class="center-align">
                    <i>Einträge konnten nicht geladen werden</i>
                </div>
            `);
            return;
        }

        this.container.html(`
            <div>
                <div class="btn waves-effect blue white-text admin_menus_save">
                    <i class="material-icons left">save</i>
                    Menü speichern
                </div>
                <div class="btn waves-effect blue white-text admin_menus_add_entry">
                    <i class="material-icons left">add</i>
                    Eintrag hinzufügen
                </div>
                <div class="btn waves-effect blue white-text admin_menus_add_page">
                    <i class="material-icons left">note_add</i>
                    Seite(n) hinzufügen
                </div>
                <div class="btn waves-effect blue white-text admin_menus_sort_alpha" title="Alphabetisch sortieren">
                    <i class="material-icons left">sort_by_alpha</i>
                    Alphabetisch sortieren
                </div>
            </div>
        `);
        let ul = $(`<ul class="menu-items"></ul>`);
        this.container.append(ul);
        for (let menuEntry of entries) {
            this.createMenuEntryListElement(ul, menuEntry);
        }

        this.initEvents();
    }
    
    getIconElement(icon) {
        let icon_src = "";
        if (icon.startsWith("mdi:")) {
            icon_src = '<i class="material-icons">' + icon.substring(4) + '</i> ';
        } 
        if (icon.startsWith("fa:")) {
            icon_src = '<i class="' + icon.substring(3) + '"></i> ';
        }
        return icon_src;
    }

    createMenuEntryListElement(container, menuEntry, animate = false) {
        let li = $(`
            <li class="menu-item" id="menu_item_${menuEntry.id}"
                data-title="${menuEntry.title}"
                data-custom_id="${menuEntry.custom_id}"
                data-custom_class="${menuEntry.custom_class}"
                data-link="${menuEntry.link}"
                data-icon="${menuEntry.icon}"
            ></li>`);
        if (animate) {
            li.hide();
        }
        if (container.is("li")) {
            if (container.find("> ul").length === 0) {
                container.append("<ul></ul>");
            }
            container = container.find("> ul");
        }
        container.append(li);
        li.append(`
            <div class="menu-item-handle ui-sortable-handle">
                <span>${this.getIconElement(menuEntry.icon)} ${menuEntry.title} :: ${menuEntry.link}</span>
                <div>
                    <i class="material-icons menu-item-delete clickable" title="Löschen">delete</i>
                    <i class="material-icons menu-item-edit clickable" title="Bearbeiten">edit</i>
                    <i class="material-icons menu-item-sort admin_menus_sort_alpha clickable" title="Alphabetisch sortieren">sort_by_alpha</i>
                    <i class="material-icons menu-item-add admin_menus_add_entry clickable" data-parent="${menuEntry.id}" title="Eintrag hinzufügen">add</i>
                    <i class="material-icons menu-item-add admin_menus_add_page clickable" data-parent="${menuEntry.id}" title="Seite(n) hinzufügen">note_add</i>
                </div>
            </div>
        `);
        if (menuEntry.children.length > 0) {
            let ul = $(`<ul></ul>`);
            li.append(ul);
            for (let child of menuEntry.children) {
                this.createMenuEntryListElement(ul, child, animate);
            }
        }
        if (animate) {
            li.slideDown("fast");
        }
        return li;
    }

    updateMenuEntryListElement(entryId) {
        let icon = $("#" + entryId).data("icon");
        let icon_src = this.getIconElement(icon);        
        $("#" + entryId + " > div:first > span").html(icon_src + $("#" + entryId).data("title") + " :: " + $("#" + entryId).data("link"));
    }

    onHierarchyChange() {

    }

    delete(id) {
        this.toDelete.push(id);
    }

    async save() {
        let entries = $(".menu-items").nestedSortable("toHierarchy", { startDepthCount: 0 });
        for (let i = 0; i < entries.length; ++i) {
            entries[i] = this.fixHierarchy(entries[i]);
        }
        try {
            let result = await $.ajax({
                method: "POST",
                data: {
                    delete: this.toDelete,
                    entries: entries,
                    menu: this.menuId
                },
                url: "api/menus/save"
            });
            return result;
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    async getEntries() {
        try {
            let result = await $.ajax({
                "url": "api/menus/list_entries",
                "data": {
                    "menu": this.menuId
                }
            });
            return result.entries;     
        } catch (e) {
            new Notification().error("Einträge konnten nicht geladen werden");
            return false;
        }
    }

    editItem(li) {
        var dlg = new Dialog();
    
        let apply = function() {
            let title = dlg.getInput("title").val();
            let link = dlg.getInput("link").val();
            let icon = dlg.getInput("icon").val();
            let custom_id = dlg.getInput("custom_id").val();
            let custom_class = dlg.getInput("custom_class").val();
            dlg.close();
            li.data("title", title);
            li.data("link", link);        
            li.data("icon", icon);
            li.data("custom_id", custom_id);
            li.data("custom_class", custom_class);
            this.updateMenuEntryListElement(li.attr("id"));
        }.bind(this);

        let cancel = function() {
            dlg.close();
        };
    
        dlg.build()
            .addTitle("Menüeintrag bearbeiten")
            .addText("Bearbeiten Sie den Menüeintrag")
            .addInput("Titel:", li.data("title"), "Titel", "title")
            .addInput("Link:", li.data("link"), "Link", "link")
            .addInput("Icon", li.data("icon"), "Icon", "icon")
            .addInput("Benutzerdefinierte ID", li.data("custom_id"), "ID", "custom_id")
            .addInput("Benutzerdefinierte Klasse", li.data("custom_class"), "Klasse", "custom_class")
            .addButton("Ok", "btnok", apply)
            .addButton("Abbrechen", "cancel", cancel)
            .show()
            .focus("title")
            .onEnter(apply)
            .onEscape(cancel);
    }


    addItemDialog(parent = null) {
        var dlg = new Dialog();
        let parentContainer = this.container.find(".menu-items");
        if (parent !== null) {
            parentContainer = this.container.find(`#menu_item_${parent}`);
        }
        
        let apply = function() {
            let title = dlg.getInput("title").val();
            let link = dlg.getInput("link").val();
            let custom_id = dlg.getInput("custom_id").val();
            let custom_class = dlg.getInput("custom_class").val();
            let icon = dlg.getInput("icon").val();

            let entry = {
                "id": "tmp_" + Date.now(),
                "title": title,
                "link": link,
                "custom_id": custom_id,
                "custom_class": custom_class,
                "icon": icon,
                "children": []
            }
            dlg.close();
            this.createMenuEntryListElement(parentContainer, entry, true)
        }.bind(this);
        let cancel = function() {
            dlg.close();
        };
    
        dlg.build()
            .addTitle("Menüeintrag erstellen")
            .addText("Erstellen Sie einen neuen Menüeintrag")
            .addInput("Titel:", "", "Titel", "title")
            .addInput("Link:", "", "Link", "link")
            .addInput("Icon", "", "Icon", "icon")
            .addInput("Benutzerdefinierte ID", "", "ID", "custom_id")
            .addInput("Benutzerdefinierte Klasse", "", "Klasse", "custom_class")
            .addButton("Ok", "btnok", apply)
            .addButton("Abbrechen", "cancel", cancel)
            .show()
            .focus("title")
            .onEnter(apply)
            .onEscape(cancel);
    }


    addPageEntry(page, availablePages, addedPages, parentContainer, withChildren = false) {
        let id = "tmp_" + Date.now() + page.id;
        let entry = {
            "id": id,
            "title": page.properties.title,
            "link": page.properties.path,
            "custom_id": "",
            "custom_class": "",
            "icon": "",
            "children": []
        }
        let li = this.createMenuEntryListElement(parentContainer, entry, false);
        addedPages.push(page.id);
        if (withChildren && Array.isArray(page.properties.children)) {            
            for (let childId of page.properties.children) {
                let childPage = availablePages[childId];
                addedPages = addedPages.concat(this.addPageEntry(childPage, availablePages, addedPages, li, withChildren));
            }
        }
        return addedPages;
    }

    addPageDialog(parent = null) {
        var dlg = new Dialog();
        let parentContainer = this.container.find(".menu-items");
        if (parent !== null) {
            parentContainer = this.container.find(`#menu_item_${parent}`);
        }
        
        let apply = async function() {
            let dlgNode = dlg.getNode();
            let pages = dlgNode.find("#menus_admin_page_select").val();
            let posts = dlgNode.find("#menus_admin_post_select").val();
            let respect_hierarchy = dlgNode.find("#admin_page_add_respect_hierarchy").is(":checked");
            let insert_hierarchy = dlgNode.find("#admin_page_add_insert_hierarchy").is(":checked");

            if (pages !== null && Array.isArray(pages)) {
                // Get pages and insert them according to hierarchy
                let availablePagesList = await loadPObjectList("\\P\\Posts\\Page");
                let availablePages = {};
                for (let page of availablePagesList) {
                    availablePages[page.id] = page;
                }
                let pagesToAdd = [];
                for (let pageId in availablePages) {
                    let page = availablePages[pageId];
                    if (page.properties.parent !== "-1") {
                        let parent = availablePages[page.properties.parent];
                        if (typeof(parent) === "undefined") {
                            continue;
                        }
                        if (!("children" in parent.properties)) {
                            parent.properties["children"] = [];
                        }
                        if (!(parent.properties["children"].includes(pageId))) {
                            parent.properties["children"].push(pageId);
                        }
                    }
                }
                let addedPages = [];
                // For each page Id, get the page object
                for (let pageId of pages) {
                    let page = availablePages[pageId];
                    pagesToAdd.push(page);
                }
                // Sort by level
                pagesToAdd.sort((a, b) => {a.properties.parents.length - b.properties.parents.length});
                // Add entries
                for (let page of pagesToAdd) {
                    if (addedPages.includes(page.id)) {
                        continue;
                    }                    
                    addedPages = this.addPageEntry(page, availablePages, addedPages, parentContainer, insert_hierarchy);
                }
            }

            if (posts !== null && Array.isArray(posts)) {
                let availablePostsList = await loadPObjectList("\\P\\Posts\\Post");
                let availablePosts = {};
                for (let post of availablePostsList) {
                    availablePosts[post.id] = post;
                }
                for (let postId of posts) {
                    let post = availablePosts[postId];
                    this.addPageEntry(post, availablePosts, [], parentContainer, false);
                }
            }

            dlg.close();
        }.bind(this);

        let cancel = function() {
            dlg.close();
        };
    
        dlg.build()
            .addTitle("Seite(n) zum Menü hinzufügen")
            .addText("Fügen Sie eine oder mehrere Seiten zum Menü hinzu.")
            .addHtml(`
                <div class="menus_admin_page_select"></div>
                <br />
                <div>
                    <label style="display: none;">
                        <input type="checkbox" class="filled-in" id="admin_page_add_respect_hierarchy" />
                        <span>Seiten-Hierarchie beachten</span>
                    </label>
                    <label>
                        <input type="checkbox" class="filled-in" id="admin_page_add_insert_hierarchy" />
                        <span>Seiten-Hierarchie automatisch hinzufügen</span>
                    </label>
                </div>
                <br />
                <div class="menus_admin_post_select"></div>
            `)
            .addButton("Ok", "btnok", apply)
            .addButton("Abbrechen", "cancel", cancel)
            .show()
            .onEnter(apply)
            .onEscape(cancel);

        initAdminPObjectSelect(dlg.getNode().find(".menus_admin_page_select"), false, {
            "input": "menus_admin_page_select",
            "title": "Seite(n) auswählen",
            "objectname": "Seiten",
            "objectclass": "\\P\\Posts\\Page",
            "objectsorter": "default:page-hierarchy",
            "objectlabler": "default:page-hierarchy",
            "selectcount": 2
        });

        initAdminPObjectSelect(dlg.getNode().find(".menus_admin_post_select"), false, {
            "input": "menus_admin_post_select",
            "title": "Post(s) auswählen",
            "objectname": "Posts",
            "objectclass": "\\P\\Posts\\Post",
            "selectcount": 2
        });
    }
    

    fixHierarchy(entry) {
        let id = entry.id;
        let link = entry.link;
        let title = entry.title;
        let icon = entry.icon;
        let custom_id = entry.custom_id;
        let custom_class = entry.custom_class;
        if (id.startsWith("menu_item_")) {
            id = id.replace("menu_item_", "");
        } 
        let children;
        if (typeof entry.children !== "undefined") {
            children = entry.children;// There are children
            for (let i = 0; i < children.length; ++i) {
                children[i] = this.fixHierarchy(children[i]);
            }
        }
        entry = {
            id: id,
            link: link,
            title: title,
            icon: icon,
            custom_id: custom_id,
            custom_class: custom_class
        };
        if (typeof children !== "undefined") {
            entry.children = children;
        }
        return entry;
    }

    // Initialize all events for sorting and clicking
    initEvents() {
        let sortableOptions = {
            forcePlaceholderSize: true,
            handle: 'div',
            items: 'li',
            maxLevels: this.maxLevels,
            toleranceElement: '> div',
            placeholder: "placeholder",
            listType: 'ul'
        }

        let that = this;

        $().ready(function(){
            $(".menu-items").nestedSortable(sortableOptions);
        });        

        this.container.on("click", ".menu-item-delete", function(){
            var par = $(this).closest(".menu-item");
            var id = par.attr("id");
            if (id.startsWith("menu_item_")) {
                id = id.split("_").pop();
                that.delete(id);
            }
            par.slideUp("fast", function() {
                let ul = par.parent();        
                par.remove();
                if (ul.children().length === 0 && !(ul.hasClass("menu-items"))) {
                    ul.remove();
                }
            });
        });

        this.container.on("click", ".menu-item-edit", function() {
            var li = $(this).closest(".menu-item");
            that.editItem(li);
        });

        this.container.on("dblclick", ".menu-items .ui-sortable-handle", function() {
            var li = $(this).closest(".menu-item");
            that.editItem(li);
        });

        this.container.on("click", ".menu-item", function(e) {
            if (e.target == this) {
                if ($(this).find("> ul").length < 0) {
                    return;
                }
                if ($(this).hasClass("collapsed")) {
                    $(this).find("> ul").slideDown();
                } else {
                    $(this).find("> ul").slideUp();
                }
                $(this).toggleClass("collapsed");
            }
        });

        this.container.on("click", ".admin_menus_save", async function() {
            let result = await that.save();
            if (result === false) {                
                new Notification().error("Speichern fehlgeschlagen");
                return;
            }
            if (result.status) {
                new Notification().success("Menü wurde gespeichert");
                // Set new IDs
                var idmap = result.idmap;
                for (let oldid in idmap) {
                    if (idmap.hasOwnProperty(oldid)) {
                        that.container.find("#" + oldid).attr("id", "menu_item_" + idmap[oldid]);
                    }
                }
            } else {           
                new Notification().error("Speichern fehlgeschlagen");
                return;
            }
        });

        this.container.on("click", ".admin_menus_add_entry", function() {
            let parent = $(this).data("parent") ?? null;
            that.addItemDialog(parent);    
        });

        this.container.on("click", ".admin_menus_add_page", function() {
            let parent = $(this).data("parent") ?? null;
            that.addPageDialog(parent);    
        });

        this.container.on("click", ".admin_menus_sort_alpha", function() {
            let parent = $(this).closest(".menu-item");
            if (parent.length === 0) {
                parent = $(".menu-items");                
            } else {
                parent = parent.find("> ul");
            }
            if (parent.length === 0) {
                return;
            }
            let items = parent.children().sort(function(a, b) {
                let titleA = $(a).data("title");
                let titleB = $(b).data("title");
                return titleA.localeCompare(titleB);
            });
            parent.append(items);
        });
        
    }
}